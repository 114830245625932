<template>
    <div id="homepage">
        <SwiperSlide :sliders="sliderList"></SwiperSlide>
        <Feature></Feature>
    </div>
</template>
<script>
import SwiperSlide from '@/components/SwiperSlide.vue'
import Feature from '../components/Feature.vue';

export default {
    components: {
        SwiperSlide,
        Feature,
    },
    data() {
        return {
            sliderList: [
                {
                    "link": require("@/assets/images/image01.jpg"),
                },{
                    "link": require("@/assets/images/image02.jpg"),
                }
            ]
        }
    }
}
</script>
