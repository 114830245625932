import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
import request from './http/request'

const app = createApp(App)

app.config.globalProperties.$request = request

app.use(store).use(router).mount("#app")
