import {createRouter, createWebHashHistory} from 'vue-router'

import Home from '@/pages/Home.vue'

const routes = [
    {
        path: "/",
        name: "home",
        component: Home
    }
];

const router = new createRouter({
    routes,
    history: createWebHashHistory(),
})

export default router
