import axios from "axios";
import qs from "qs";

axios.interceptors.request.use(
    async config => {
        config.headers.token = sessionStorage.getItem('token')
        return config
    }, error => {
        return Promise.error(error)
    }
)

axios.interceptors.response.use(
    response => {
        if (response.status == 200) {
            return Promise.resolve(response)
        } else {
            return Promise.reject(response)
        }
        
    }, error => {
        if (error.response.status) {
            switch(error.response.status) {
                case 401:
                    break
                case 403:
                    break
                case 404:
                    break        
            }
            return Promise.reject(error.response)
        }
    }
)

const $get = (url, params) => {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params,
        }).then(res => {
            resolve(res)
        }).then(err => {
            reject(err)
        })
    })
}

const $post = (url, params) => {
    return new Promise((resolve, reject) => {
        axios.post(url, qs.stringify(params))
            .then(res => {
                resolve(res)
            }).then(err => {
                reject(err)
            })
    })
}

const request = {
    axios: axios,
    post: $post,
    get: $get,
}

export default request