<template>
    <div class="footer">
        <div class="filing"><a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2022043202号-1</a></div>
    </div>
</template>
<script>
export default {
}
</script>

<style lang="scss">
.footer {
    background-color: #222;
    min-height: 40px;
    width: 100%;
    color:#fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .filing {
        a, a:hover, a:visited, a:link { color: #666 }
    }
}
</style>
