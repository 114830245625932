<template>
    <div class="feature">
        <ImageText class="item" :media="item" v-for="(item, index) in imageTextList" :key="index"></ImageText>
    </div>
</template>
<script>
import ImageText from '@/components/media/ImageText.vue'

export default {
    components: {
        ImageText,
    },
    data() {
        return {
            list: [{
                    "height": "300px" 
                },{
                    "height": "200px" 
                },{
                    "height": "200px" 
                },{
                    "height": "200px" 
                },{
                    "height": "300px" 
                },{
                    "height": "400px" 
                }],
            imageTextList: [
                {
                    "image": require("@/assets/images/image01.jpg"),
                    "text": "write something here~"
                },{
                    "image": require("@/assets/images/image02.jpg"),
                    "text": "write something here~"
                },{
                    "image": require("@/assets/images/image03.jpg"),
                    "text": "write something here~"
                },{
                    "image": require("@/assets/images/image04.webp"),
                    "text": "write something here~"
                },{
                    "image": require("@/assets/images/image05.jpeg"),
                    "text": "write something here~"
                },
            ]
        }
    }
}
</script>
<style lang="scss">
    .feature { 
        display: flex;
        flex-direction: column;
        padding: 10px;

        .item {
            margin: 5px;
            min-height: 200px;
        }
    }
</style>