<template>
    <div id="header" class="header">
        <div class="site-name">
            {{ siteName }}
        </div>
        <div class="account">
            <i class="fa fa-user fa-lg"></i>
        </div>
    </div>
</template>
<script>
import { getCurrentInstance } from "vue"

export default {
    setup() {
        const {proxy} = getCurrentInstance()

        proxy.$request.post("/api/test.php", {"name": "Chalex", "age": 28}).then(res => {
            console.log(res)
        })
    },
    data() {
        return {
            siteName: "CHALEX.CN"
        }
    }
}
</script>
<style lang="scss">
    .header {
        width: 100%;
        background-color: #0af;
        color: #fff;
        min-height: 40px;
        display: grid;
        grid-template-areas:'a b';
        align-items: center;

        $padding: 0 20px;
        $fontSize: 1.5em;

        .site-name {
            padding: $padding;
            font-size: $fontSize;
            justify-self: self-start;
        }
        .account {
            justify-self: self-end;
            padding: $padding;
        }
    }
</style>