<template>
        <div class="image-text-box">
            <div class="text">{{ media.text }}</div>
            <div class="image"><img :src="media.image"></div>
        </div>
</template>

<script>
    export default {
        props: ["media"],
        data() {
            return {
                
            }
        }
    }
</script>

<style lang="scss" scoped>
    .image-text-box {
        display: flex;
        background-color: #eee;
        height: 230px;
  
        $width: 90%;
        $border-radius:10px;

        width: $width;

        border-radius: $border-radius;

        $to-distance: 12%;
        @keyframes move2right {
            from { transform: translateX(0); }
            to { transform: translateX(calc(100% - #{$width})); }
        }
        @keyframes move2left {
            from { transform: translateX(0); }
            to { transform: translateX(calc(#{$width} - 100%)); }
        }
        @mixin animation-move {
            animation-duration: 5s;
            animation-timing-function: ease-in-out;
            animation-direction: alternate;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
            animation-delay: 0s;
        }
        &:nth-child(odd) {
            justify-content: left;
            flex-direction: row-reverse;
            animation-name: move2right;
            @include animation-move();
        }
        &:nth-child(even) {
            animation-name: move2left;
            @include animation-move();
            justify-content: right;
            margin-left: calc(100% - #{$width});
        }
        .image {
            @include flex();
            img {
                max-height: 100%;
                max-width: 100%;
                padding: $border-radius;
            }
        }

        .text {
            color: #000;
            font-size: 1.2em;
            line-height: 25px;
            padding: 10px 25px;
            font-family:Arial, Helvetica, sans-serif;
            font-weight: normal;
            vertical-align: middle;   
            border-top-left-radius: $border-radius;
            border-bottom-left-radius: $border-radius;

            @include flex();
        }
    }
</style>